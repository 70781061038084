import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_hxpx9TAykM4St_ybsPIAJmhOKQmae5Yd749dL71wvC8 from "/vercel/path0/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import restore_state_client_Avkf9UKa4FqvXNYdAVbcnnVMLrsnZ1K_mmTT61bXxXc from "/vercel/path0/node_modules/nuxt/dist/app/plugins/restore-state.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import siteConfig_ljJNkxNDnMErHBcNcXJ27hjy34k76YtojZHNtaENIhc from "/vercel/path0/node_modules/nuxt-seo-utils/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_vhQ2e80vT_932XiNawlrw39m2mZ9shY5wTL9hw9MNdU from "/vercel/path0/node_modules/nuxt-seo-utils/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import titles_47I_JQPANZF7C31QlMa6zx_xr5i7n_LERBj9HdLKeZo from "/vercel/path0/node_modules/nuxt-seo-utils/dist/runtime/nuxt/plugins/titles.js";
import plugin_7DB4Q_rSg7Y6_r2xTJAR9sj0Plzl7GeeI8C3uqDzeqY from "/vercel/path0/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import slideovers_g1RyjnFCC2XCqS3NLpv7yngCZ5AzjpmT7UJr_dW3BbY from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_JhH8M1KzF3pQyhcHsoNTBLd8tKGet6zo2zTBVDe7nK4 from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_E7kSti5pGZ28QhUUurq6gGRU3l65WuXO_KJC3GQgzFo from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg from "/vercel/path0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8 from "/vercel/path0/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_J2UXS7dnxSvv3PlFW8rqkjoRJICKCPmO_PloFL_AXDo from "/vercel/path0/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import chunk_error_handler_X8DbwRc2F6QXTxtLkFSdrlUFQ2hO8Y90oYPJAprgtgo from "/vercel/path0/plugins/chunk-error-handler.ts";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/vercel/path0/plugins/sentry.client.ts";
import defaults_hdCcn3sNK9_w7nthbVP669c6ef69VP_bbgdpRIIo80g from "/vercel/path0/node_modules/nuxt-seo-utils/dist/runtime/nuxt/plugins/defaults.js";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_hxpx9TAykM4St_ybsPIAJmhOKQmae5Yd749dL71wvC8,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  restore_state_client_Avkf9UKa4FqvXNYdAVbcnnVMLrsnZ1K_mmTT61bXxXc,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  siteConfig_ljJNkxNDnMErHBcNcXJ27hjy34k76YtojZHNtaENIhc,
  inferSeoMetaPlugin_vhQ2e80vT_932XiNawlrw39m2mZ9shY5wTL9hw9MNdU,
  titles_47I_JQPANZF7C31QlMa6zx_xr5i7n_LERBj9HdLKeZo,
  plugin_7DB4Q_rSg7Y6_r2xTJAR9sj0Plzl7GeeI8C3uqDzeqY,
  slideovers_g1RyjnFCC2XCqS3NLpv7yngCZ5AzjpmT7UJr_dW3BbY,
  modals_JhH8M1KzF3pQyhcHsoNTBLd8tKGet6zo2zTBVDe7nK4,
  colors_E7kSti5pGZ28QhUUurq6gGRU3l65WuXO_KJC3GQgzFo,
  plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg,
  plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8,
  plugin_J2UXS7dnxSvv3PlFW8rqkjoRJICKCPmO_PloFL_AXDo,
  chunk_error_handler_X8DbwRc2F6QXTxtLkFSdrlUFQ2hO8Y90oYPJAprgtgo,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE,
  defaults_hdCcn3sNK9_w7nthbVP669c6ef69VP_bbgdpRIIo80g
]